import { useEffect, useState } from 'react'
import logo from './logo.svg';
import './App.css';

function App() {
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchUsers = async () => {
      const headers = new Headers()
      headers.append('Accept', 'application/json')
      headers.append('Content-Type', 'application/json')
      const response = await fetch('/api/users', { method: 'GET', headers })
      const json = await response.json()
      console.log(json)
    }

    fetchUsers()
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      {users.map(user => (
        <div>{user.name}</div>
      ))}
    </div>
  );
}

export default App;
